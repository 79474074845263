import { addFilter } from '@situation/setdesign.util';

const defaultSettings = {
    items: 1, margin: 0, dots: false, loop: true, nav: true,
};
addFilter('media-carousel/owl-settings', (settings) => ({ ...settings, nav: true, loop: true }));
SetDesign(($, _, window) => {
    // initialize Image Carousel component slider
    $('.carousel:has(> figure)').each((index, elem) => {
        const $slider = $(elem);
        $slider.addClass('owl-carousel image-carousel').owlCarousel(defaultSettings);
    });

    const breakpoint = getComputedStyle(document.documentElement).getPropertyValue(
        '--breakpoint-md',
    );
    const matchBreakpoint = () => window.matchMedia(`(min-width: ${breakpoint})`).matches;

    const initializeSlider = ($slider) => {
        // initialize based on data attribute options
        const sliderOptions = $slider.data('owl-config') || {};
        $slider.addClass('owl-carousel').owlCarousel(sliderOptions);
    };

    const destroySlider = ($slider) => {
        $slider.trigger('destroy.owl.carousel').removeClass('owl-carousel');
    };

    let lastKnownState = null;

    const updateSliders = () => {
        const currentState = matchBreakpoint();
        if (lastKnownState !== currentState) {
            lastKnownState = currentState;
            $('.content-slider:not(.content-slider--mobile)').each((index, elem) => {
                const $slider = $(elem);
                if (currentState) {
                    initializeSlider($slider);
                } else {
                    destroySlider($slider);
                }
            });
        }
    };

    // update sliders on page load and window resize
    $().ready(updateSliders);
    $(window).on('resize', updateSliders);
});
