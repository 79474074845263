// responsive top/bottom positioned full width section backgrounds
SetDesign(($) => {
    $(() => {
        const responsiveBackgroundSections = $('section.responsive-background');
        responsiveBackgroundSections.each((index, elem) => {
            const $section = $(elem);
            const $bgSpan = $section.find('.content-background-image__img');
            if ($bgSpan.length) {
                const imageSrc = $bgSpan
                    .css('background-image')
                    .replace(/url\((['"])?(.*?)\1\)/gi, '$2');
                const aImage = new Image();

                aImage.onload = () => {
                    $bgSpan.data('background-image-size', {
                        width: aImage.width,
                        height: aImage.height,
                    });

                    $bgSpan.css('background-size', '100vw auto');
                };

                aImage.src = imageSrc;
            }
        });

        const updateResponseBackgrounds = () => {
            responsiveBackgroundSections.each((index, elem) => {
                const $section = $(elem);
                const $bgSpan = $section.find('.content-background-image__img');
                const bgSize = $bgSpan.data('background-image-size');
                if (bgSize && bgSize.height) {
                    // eslint-disable-next-line max-len
                    const vw = Math.max(
                        document.documentElement.clientWidth || 0,
                        window.innerWidth || 0,
                    );
                    const actualHeight = vw * (bgSize.height / bgSize.width);
                    if ($section.height() < actualHeight) {
                        $bgSpan.css('background-position-y', 'top');
                    } else {
                        $bgSpan.css('background-position-y', 'bottom');
                    }
                }
            });

            window.requestAnimationFrame(updateResponseBackgrounds);
        };

        window.requestAnimationFrame(updateResponseBackgrounds);
    });
});
