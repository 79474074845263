import { $ } from '@situation/setdesign.util';

$(() => {
    const $floatingSections = $('section.content-section--floating-section');
    const desktopWidth = getComputedStyle(document.documentElement).getPropertyValue(
        '--breakpoint-md',
    );

    const adjustFloatingSections = () => {
        const isDesktop = window.matchMedia(`(min-width: ${desktopWidth})`).matches;
        $floatingSections.each((_, elem) => {
            const $section = $(elem);
            const sectionHeight = elem.getBoundingClientRect().height;
            const skip = $section.hasClass('content-section--floating-section--desktop-only') && !isDesktop;
            $section.prev().css('padding-bottom', skip ? '' : sectionHeight / 2);
            $section.next().css('padding-top', skip ? '' : sectionHeight / 2);
        });

        window.requestAnimationFrame(adjustFloatingSections);
    };

    window.requestAnimationFrame(adjustFloatingSections);
});
