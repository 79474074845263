import { addAction } from '@situation/setdesign.util';

SetDesign(($) => {
    const STICKY_SELECTOR = 'sticky-header';
    const STICKY_MODIFIER = `${STICKY_SELECTOR}--sticky`;
    const STICKY_MODIFIER_TOP = `${STICKY_SELECTOR}--top`;
    const STICKY_HEADER_SHOW = 'stickyHeaderShow';
    const STICKY_HEADER_HIDE = 'stickyHeaderHide';
    const $header = $(`.${STICKY_SELECTOR}`)
        .addClass(STICKY_MODIFIER)
        .removeClass(STICKY_MODIFIER_TOP);
    const $body = $('body');
    addAction(STICKY_HEADER_SHOW, () => {
        $header.addClass(STICKY_MODIFIER).removeClass(STICKY_MODIFIER_TOP);
        $body.addClass('header-offset');
    });

    addAction(STICKY_HEADER_HIDE, () => {
        $header.addClass(STICKY_MODIFIER).removeClass(STICKY_MODIFIER_TOP);
        $body.addClass('header-offset');
    });
});
