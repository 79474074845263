import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $ticketLinks = $('a[href="#tickets-modal"]');
    if (window.hasOwnProperty('ventrata')) {
        $ticketLinks
            .on('click', (e) => {
                e.preventDefault();
            })
            .each((_, a) => {
                window.ventrata.createFrame({
                    button: a,
                    layout: 'button',
                    product: '4a7e956e-59e9-4509-92b8-91c6c71011b0',
                });
            });
    }
});
